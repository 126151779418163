import './general-sans.css';
import './App.css';
import { Fab, Stack, ThemeProvider } from '@mui/material';
import { appTheme } from './theme/appTheme';
import { Outlet, RouterProvider, createBrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import { store } from './api/store';
import Main from './genetics/Main';
import TraitDetails from './genetics/TraitDetails';
import { Person } from '@mui/icons-material/';
import { purple } from '@mui/material/colors';
import UsersInfoModal from './genetics/UsersInfoModal';
import { useState } from 'react';

const Wrapper = () => {
  const [showModal, setShowModal] = useState(false);

  return (
    <Stack
      sx={{
        position: 'fixed',
        top: 0,
        bottom: 0,
        width: '100%',
        height: '100%',
        overflowY: 'scroll',
      }}
    >
      <Outlet />

      <Fab
        sx={{
          position: 'fixed',
          bottom: '40px',
          right: '40px',
          backgroundColor: purple[400],
          '&:hover': {
            backgroundColor: purple[200],
          },
        }}
        onClick={() => setShowModal(true)}
      >
        <Person htmlColor={'white'} />
      </Fab>

      <UsersInfoModal open={showModal} onClose={() => setShowModal(false)} />
    </Stack>
  );
};

const router = createBrowserRouter([
  {
    path: '/',
    element: <Wrapper />,
    children: [
      { path: '/:hash/', element: <Main /> },
      { path: '/:hash/:sectionCode/:traitId', element: <TraitDetails /> },
    ],
  },
]);

const App = () => (
  <Provider store={store}>
    <ThemeProvider theme={appTheme}>
      <RouterProvider router={router} />
    </ThemeProvider>
  </Provider>
);

export default App;
