import { Box, Stack, Typography } from '@mui/material';
import React, { useMemo } from 'react';
import { ISnps } from '../api/types';
import { createStyleUtils } from '../common/createStyleUtils';

export default function SNPSView(props: Props) {
  const {
    snps: { snp, risk_allele_id, gene, genotype, risk_allele_code },
  } = props;

  const width = useMemo(() => {
    switch (risk_allele_code) {
      case 0:
      default:
        return '33%';
      case 1:
        return '66%';
      case 2:
        return '100%';
    }
  }, [risk_allele_code]);

  return (
    <Stack sx={styles.container} direction={'column'}>
      <Typography sx={styles.title}>
        {gene}: {snp}
      </Typography>

      <Stack sx={styles.progressContainer}>
        <Box sx={styles.progressBackground} />
        <Box sx={[styles.progress, { width }]} />
      </Stack>

      <Stack direction={'row'} justifyContent={'center'} gap="30px">
        <Stack sx={styles.alleleColumn}>
          <Typography sx={styles.alleleTitle}>{risk_allele_id}</Typography>
          <Typography sx={styles.alleleColumn}>Allele</Typography>
        </Stack>

        <Stack sx={styles.alleleColumn}>
          <Typography sx={styles.alleleTitle}>{genotype}</Typography>
          <Typography sx={styles.alleleColumn}>Genotype</Typography>
        </Stack>
      </Stack>
    </Stack>
  );
}

interface Props {
  snps: ISnps;
}

const styles = createStyleUtils({
  container: {
    padding: '20px',
    backgroundColor: 'white',
    borderRadius: '24px',
    width: '45%',
    flexBasis: '45%',
    marginRight: '20px',
    position: 'relative',
    boxSizing: 'border-box',
    marginTop: '20px',
  },

  placeholder: {
    borderRadius: '24px',
    width: '300px',
    marginRight: '20px',
    height: '148px',
  },

  title: {
    marginBottom: '10px',
    fontWeight: '600',
    color: '#292D32',
    fontSize: '13px',
    lineHeight: '16px',
    height: '32px',
  },

  progressContainer: {
    alignSelf: 'stretch',
    marginBottom: '10px',
    position: 'relative',
  },

  progressBackground: {
    width: '100%',
    height: '12px',
    borderRadius: '8px',
    backgroundColor: 'rgba(41, 45, 50, 0.2)',
  },

  progress: {
    position: 'absolute',
    top: 0,
    bottom: 0,
    width: '30%',
    borderRadius: '8px',
    backgroundColor: '#0ABAB5',
  },

  alleleColumn: {
    flexDirection: 'column',
    alignItems: 'center',
    marginHorizontal: 5,
  },

  alleleTitle: {
    marginBottom: '10px',
    fontWeight: '600',
    color: '#292D32',
    fontSize: '13px',
  },

  alleleLabel: {
    color: '#292D32',
    fontSize: '8px',
  },
});

export const snpsStyles = styles;
