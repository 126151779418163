import { Box, Modal, Stack, Typography } from '@mui/material';
import { createStyleUtils } from '../common/createStyleUtils';
import { useParams } from 'react-router-dom';
import { useGetUserInfoQuery } from '../api/api';
import dayjs from 'dayjs';
import { useMemo } from 'react';

export default function UsersInfoModal(props: Props) {
  const { open, onClose } = props;

  const { hash = '' } = useParams<{ hash: string }>();
  const { data: user } = useGetUserInfoQuery(hash, { skip: hash === '' });

  const gender = useMemo(() => {
    if (!user) {
      return null;
    }

    if (user.gender.toUpperCase() === 'M') {
      return 'Male';
    } else if (user.gender.toUpperCase() === 'F') {
      return 'Female';
    } else if (user.gender.toUpperCase() === 'NB') {
      return 'Non-binary';
    }

    return user.gender.substring(0, 1).toUpperCase() + user.gender.substring(1);
  }, [user]);

  if (!user) {
    return null;
  }

  return (
    <Modal open={open} sx={styles.container} onClose={onClose}>
      <Stack
        direction={'row'}
        sx={styles.contentContainer}
        gap={'20px'}
        alignItems={'center'}
      >
        <Box
          component={'img'}
          src={`https://d3f9ze44v0fr12.cloudfront.net/uploads/user/profile_image/${user.id}/${user.profile_image}`}
          sx={{
            width: '120px',
            height: '120px',
            borderRadius: '60px',
            objectFit: 'cover',
          }}
        />

        <Stack gap={'10px'}>
          <Typography variant="h4">
            {user.first_name} {user.last_name}
          </Typography>
          <Typography>{user.email}</Typography>
          <Typography>{gender}</Typography>
          <Typography>
            {dayjs().diff(dayjs(user.birth_date), 'years')} years old
          </Typography>
        </Stack>
      </Stack>
    </Modal>
  );
}

const styles = createStyleUtils({
  container: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },

  contentContainer: {
    backgroundColor: 'white',
    padding: '40px',
    minWidth: '300px',
    borderRadius: '36px',
  },
});

interface Props {
  open: boolean;
  onClose: () => void;
}
