import { Box, Typography } from '@mui/material';
import { useLabelsUI } from './common';
import { createStyleUtils } from '../../common/createStyleUtils';

export default function LargeLabel(props: Props) {
  const { score } = props;

  const { circleColor, text } = useLabelsUI(score, false);

  return (
    <Box sx={styles.labelContainer}>
      <Box sx={[styles.circle, { backgroundColor: circleColor }]} />
      <Typography sx={styles.title}>{text}</Typography>
    </Box>
  );
}

const styles = createStyleUtils({
  labelContainer: {
    padding: '6px 12px',
    borderRadius: '36px',
    backgroundColor: 'white',
    flexDirection: 'row',
    marginBottom: '24px',
    marginTop: '8px',
    alignSelf: 'center',
    display: 'flex',
  },

  circle: {
    marginRight: '6px',
    width: '16px',
    height: '16px',
    borderRadius: '8px',
  },

  title: {
    fontWeight: '500',
    fontSize: '14px',
    lineHeight: '16px',
    color: '#292D3299',
  },
});

interface Props {
  score: number;
}
