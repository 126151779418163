import { createStyleUtils } from '../common/createStyleUtils';

export const principleViewStyles = createStyleUtils({
  container: {
    padding: '24px',
    backgroundColor: 'white',
    borderRadius: '16px',

    width: 0,
    flexGrow: 1,
    marginRight: '10px',
    '&:hover': {
      cursor: 'pointer',
    },
  },

  placeholderContainer: {
    width: 0,
    flexGrow: 1,
    marginRight: '10px',
    height: '68px',
  },

  placeholder: {
    borderRadius: '16px',
    width: '100%',
    height: '100%',
  },

  image: {
    width: '24px',
    height: '24px',
    marginBottom: '10px',
    tintColor: '#292D32',
  },

  text: {
    color: '#292D32',
    fontSize: '14px',
  },
});

export const geneticsScreenStyles = createStyleUtils({
  pricinplesContainer: {
    margin: '20px 0px',
    alignSelf: 'center',
    justifyContent: 'space-between',
    width: '800px',
  },

  traitsContainer: {
    alignSelf: 'stretch',
    justifyContent: 'center',
    alignItems: 'center',
  },

  snpsContainer: {
    padding: '0px 30px',
    marginTop: '15px',
  },
});

export const traitViewStyles = createStyleUtils({
  container: {
    flexBasis: '49%',
    width: '49%',
    padding: '20px',
    boxSizing: 'border-box',

    backgroundColor: 'white',
    borderRadius: '24px',
    marginTop: '20px',
    '&:hover': {
      cursor: 'pointer',
    },
  },

  placeholderContainer: {
    flexBasis: '45%',
    width: '45%',
    height: '158px',
    marginTop: '20px',
    marginRight: '10px',
  },

  placeholder: {
    width: '100%',
    height: '100%',
    borderRadius: '24px',
  },

  text: {
    fontWeight: '600',
    color: '#292D32',
    fontSize: '13px',
    lineHeight: '16px',
  },

  scoreContainer: {
    position: 'absolute',
    left: '10px',
    right: '10px',
    top: '0px',
    bottom: '0px',
  },

  scorePercentileContainer: {
    flexDirection: 'row',
    alignSelf: 'center',
  },

  image: {
    width: '116px',
    height: '116px',
  },

  score: {
    fontWeight: '600',
    fontSize: '24px',
    lineHeight: '32px',
    color: '#292D32',
  },

  suffixTraitDetails: {
    fontWeight: '600',
    fontSize: '24px',
    lineHeight: '32px',
    color: '#292D32',
    marginTop: '18px',
  },

  scoreSuffix: {
    fontWeight: '600',
    fontSize: '16px',
    lineHeight: '24px',
    color: '#292D32',
  },

  percentile: {
    fontSize: '10px',
    lineHeight: '10px',
    color: '#716F74',
  },

  percentileTraitDetails: {
    alignSelf: 'center',
    marginTop: '0px',
    fontSize: '13px',
    lineHeight: '16px',
    color: '#716F74',
    marginBottom: '8px',
  },
});

export const traitViewDetailsStyles = createStyleUtils({
  score: {
    alignSelf: 'center',
    color: 'black',
    fontSize: '60px',
    lineHeight: '60px',
    fontWeight: '600',
  },

  nameContainer: {
    marginBottom: '10px',
    flexDirection: 'row',
  },

  image: {
    marginRight: '20px',
    tintColor: 'black',
    width: '32px',
    height: '32px',
  },

  name: {
    fontWeight: '600',
    color: 'black',
    fontSize: 24,
  },
});

export const lqiCurveStyles = createStyleUtils({
  container: {
    paddingTop: '30px',
    backgroundColor: 'white',
    borderRadius: '24px',
    alignSelf: 'center',
    position: 'relative',
  },

  placeholder: {
    borderRadius: '24px',
    height: '164px',
    marginHorizontal: '24px',
    alignSelf: 'stretch',
  },

  avatar: {
    marginTop: '5,px',
  },

  separator: {
    position: 'absolute',
    top: 0,
    bottom: 0,
    width: '1px',
    backgroundColor: '#0ABAB5',
  },

  averageContainer: {
    flexDirection: 'row',
    alignItems: 'flex-start',
  },

  averageLine: {
    alignSelf: 'stretch',
    borderWidth: 1,
    borderStyle: 'dashed',
    borderColor: '#292D3266',

    transform: 'translateX(14px)',
  },

  averageText: {
    marginLeft: '4px',
    color: '#292D3266',
    fontSize: '10px',
    lineHeight: '12px',
    transform: 'translateX(14px)',
  },

  absoluteContainer: {
    position: 'absolute',
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
  },
});

export const lqiViewStyles = createStyleUtils({
  background: {
    position: 'absolute',
    left: 0,
    top: 0,
    width: '100%',
    height: '100%',
  },

  score: {
    alignSelf: 'center',
    color: 'black',
    fontSize: '60px',
    lineHeight: '60px',
    fontWeight: '600',
  },

  percentile: {
    fontWeight: '600',
    fontSize: '24px',
    lineHeight: '32px',
    color: '#292D32',
  },

  scoreContainer: {
    marginTop: '30px',
    marginBottom: '20px',
  },

  nameContainer: {
    marginBottom: '10px',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
  },

  image: {
    marginRight: '20px',
    tintColor: 'black',
    width: '32px',
    height: '32px',
  },

  name: {
    fontWeight: '600',
    color: 'black',
    fontSize: '24px',
  },

  betterThan: {
    marginBottom: '24px',
    alignSelf: 'center',
    color: 'rgba(41, 45, 50, 0.6)',
    fontSize: '13px',
  },

  betterThanPlaceholder: {
    width: '140px',
    height: '13px',
    marginBottom: '24px',
    borderRadius: '4px',
    alignSelf: 'center',
  },

  description: {
    marginTop: '40px',
    alignSelf: 'stretch',
    color: '#716F74',
    marginHorizontal: '30px',
    fontSize: '16px',
    lineHeight: '24px',
  },

  readMore: {
    marginTop: '20px',
    marginLeft: '30px',
    color: 'black',
    fontSize: '16px',
  },

  topContributors: {
    marginLeft: '30px',
    marginVertical: '30px',
    color: 'black',
    fontWeight: '600',
    fontSize: '20px',
  },

  title: {
    fontWeight: '600',
    color: 'black',
    fontSize: '16px',
    marginHorizontal: '30px',
    marginTop: '40px',
  },

  content: {
    color: '#716F74',
    fontSize: '16px',
    lineHeight: '20px',
    marginHorizontal: '30px',
    marginTop: '20px',
  },

  backgroundImageContainer: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    justifyContent: 'center',
    alignItems: 'center',
  },
});
