import { useMemo } from 'react';
import { getSuffix } from './common';
import { ITrait } from '../api/types';
import { Box, Stack, Typography } from '@mui/material';
import { traitViewStyles } from './styles';

import traitScore33 from '../assets/trait/genetic_trait_33.png';
import traitScore66 from '../assets/trait/genetic_trait_66.png';
import traitScore100 from '../assets/trait/genetic_trait_100.png';
import SmallLabel from './labels/SmallLabel';

export default function TraitView(props: Props) {
  const {
    trait: { name, score },
    onPress,
  } = props;

  const suffix = useMemo(() => {
    return getSuffix(score);
  }, [score]);

  const imageSource = useMemo(() => {
    if (score < 0.33) {
      return traitScore33;
    } else if (score >= 0.33 && score < 0.66) {
      return traitScore66;
    } else {
      return traitScore100;
    }
  }, [score]);

  return (
    <Stack
      onClick={() => onPress(props.trait)}
      sx={traitViewStyles.container}
      direction={'column'}
    >
      <Stack sx={{ minHeight: '65px' }} gap={'8px'}>
        <Typography sx={traitViewStyles.text}>{name}</Typography>
        <SmallLabel score={props.trait.score} />
      </Stack>

      <Stack sx={{ alignSelf: 'center', position: 'relative' }}>
        <Box component="img" src={imageSource} sx={traitViewStyles.image} />

        <Stack
          sx={traitViewStyles.scoreContainer}
          justifyContent={'center'}
          alignItems={'center'}
          direction={'column'}
        >
          <Typography sx={traitViewStyles.score}>
            {Math.round(score * 100)}
            <Typography component={'span'} sx={traitViewStyles.scoreSuffix}>
              {suffix}
            </Typography>
          </Typography>

          <Typography sx={traitViewStyles.percentile}>percentile</Typography>
        </Stack>
      </Stack>
    </Stack>
  );
}

interface Props {
  trait: ITrait;

  onPress: (trait: ITrait) => void;
}
