import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { nodeAPI } from './ml';
import { IPrinciple, ITrait, ITraitInfo, User } from './types';

export const api = createApi({
  reducerPath: 'api',
  baseQuery: fetchBaseQuery({ baseUrl: '/' }),
  endpoints: (build) => ({
    getPrinciples: build.query<IPrinciple[], string>({
      queryFn: async (hash) => {
        const {
          data: { genetic_groups },
        } = await nodeAPI.get<{ genetic_groups: IPrinciple[] }>(
          `${hash}/principles/`
        );

        return { data: genetic_groups };
      },
    }),

    getTraits: build.query<ITrait[], { hash: string; groupId: string }>({
      queryFn: async ({ hash, groupId }) => {
        const {
          data: { traits },
        } = await nodeAPI.get<{ traits: ITrait[] }>(
          `/${hash}/traits?groupId=${groupId}`
        );

        return { data: traits };
      },
    }),

    getTraitsDetails: build.query<
      ITraitInfo,
      { hash: string; reportId: string }
    >({
      queryFn: async ({ hash, reportId }) => {
        const { data } = await nodeAPI.get<ITraitInfo>(
          `/${hash}/traits/${reportId}`
        );

        return { data };
      },
    }),

    getUserInfo: build.query<User, string>({
      queryFn: async (hash) => {
        const { data } = await nodeAPI.get<User>(`/${hash}`);

        return { data };
      },
    }),
  }),
});

export const {
  useGetPrinciplesQuery,
  useGetTraitsQuery,
  useGetTraitsDetailsQuery,
  useGetUserInfoQuery,
} = api;
