import { Stack } from '@mui/material';
import { useGetPrinciplesQuery, useGetTraitsQuery } from '../api/api';
import { geneticsScreenStyles } from './styles';
import PrincipleView from './PrincipleView';
import { useMemo } from 'react';
import TraitView from './TraitView';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';

export default function Main() {
  const { hash = '' } = useParams<{ hash: string }>();
  const [params, setParams] = useSearchParams();

  const { data: principles } = useGetPrinciplesQuery(hash);

  const selectedIndex = useMemo(() => {
    const index = params.get('index');
    if (index !== null) {
      return parseInt(index);
    }

    return 0;
  }, [params]);

  const { data: traits } = useGetTraitsQuery(
    {
      hash,
      groupId: principles ? principles[selectedIndex].section_code : '',
    },
    {
      skip: principles === undefined,
    }
  );

  const navigate = useNavigate();

  return (
    <Stack sx={geneticsScreenStyles.pricinplesContainer} direction={'column'}>
      <Stack
        justifyContent={'space-between'}
        alignItems={'center'}
        direction={'row'}
      >
        {principles &&
          principles.map((principle, index) => (
            <PrincipleView
              key={principle.section_code}
              onSelected={() => setParams({ index: index.toString() })}
              principle={principle}
              selected={index === selectedIndex}
            />
          ))}
      </Stack>

      <Stack
        direction={'row'}
        sx={{ alignSelf: 'stretch', flexWrap: 'wrap' }}
        justifyContent={'space-between'}
      >
        {traits &&
          traits.map((item) => (
            <TraitView
              key={item.id}
              onPress={() => {
                if (principles) {
                  navigate(
                    `${principles[selectedIndex].section_code}/${item.id}`
                  );
                }
              }}
              trait={item}
            />
          ))}
      </Stack>
    </Stack>
  );
}
