import { principleViewStyles } from './styles';
import { Box, Stack, Typography } from '@mui/material';
import { IPrinciple } from '../api/types';
import { ASSETS_URL } from '../environments/environments';

export default function PrincipleView(props: Props) {
  const {
    principle: { name, mobile_image_url, section_code },
    selected,
    onSelected,
  } = props;

  return (
    <Stack
      onClick={() => onSelected(section_code)}
      justifyContent={'center'}
      alignItems={'center'}
      direction={'column'}
      sx={[
        principleViewStyles.container,
        selected && { backgroundColor: '#0ABAB5' },
      ]}
    >
      <Box
        component="img"
        src={`${ASSETS_URL}/uploads/genetics_v4/icons/${
          selected ? 'White' : 'Black'
        }/${mobile_image_url}`}
        sx={[principleViewStyles.image, selected && { tintColor: 'white' }]}
      />

      <Typography
        sx={[principleViewStyles.text, selected && { color: 'white' }]}
      >
        {name}
      </Typography>
    </Stack>
  );
}

interface Props {
  principle: IPrinciple;
  selected: boolean;
  onSelected: (code: string) => void;
}
