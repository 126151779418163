interface Environment {
  apiURL: string;
  apiKey: string;
}

const alpha: Environment = {
  apiURL: 'https://alpha.app.iamyiam.com/api/v2/sandy/genetics',
  apiKey: '6fb76003-ad32-42cc-913a-b756b28a0747',
};

const devAlpha: Environment = {
  ...alpha,
  apiURL: 'http://localhost:8000/api/v2/sandy/genetics',
};

const prod: Environment = {
  apiURL: 'https://www.iamyiam.com/api/v2/sandy/genetics',
  apiKey: 'b0c134a7-17e9-4569-be82-d4a5f7f5e3e2',
};

const devProd: Environment = {
  ...prod,
  apiURL: 'http://localhost:8000/api/v2/sandy/genetics',
};

const environments = {
  alpha,
  devAlpha,
  prod,
  devProd,
};

export const appEnvironment =
  environments[process.env.REACT_APP_ENVIRONMENT as keyof typeof environments];

export const ASSETS_URL = 'https://d3b3zp0qkrdpk.cloudfront.net';
