export const getSuffix = (score: number) => {
  const roundedScore = Math.round(score * 100);
  const modulo10 = roundedScore % 10;
  const modulo100 = roundedScore % 100;

  if (modulo10 == 1 && modulo100 != 11) {
    return 'st';
  }
  if (modulo10 == 2 && modulo100 != 12) {
    return 'nd';
  }
  if (modulo10 == 3 && modulo100 != 13) {
    return 'rd';
  }
  return 'th';
};
