import { useMemo } from 'react';

export const useLabelsUI = (score: number, abbreviate: boolean) => {
  const circleColor = useMemo(() => {
    if (score < 0.33) {
      return '#E84D8A';
    } else if (score >= 0.33 && score < 0.66) {
      return '#F7C824';
    } else {
      return '#0ABAB5';
    }
  }, [score]);

  const text = useMemo(() => {
    if (score < 0.33) {
      return abbreviate ? 'Gen. disadvantage' : 'Genetical disadvantage';
    } else if (score >= 0.33 && score < 0.66) {
      return 'Genetical average';
    } else {
      return abbreviate ? 'Gen. advantage' : 'Genetical advantage';
    }
  }, [abbreviate, score]);

  return { circleColor, text };
};
