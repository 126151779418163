import { AppStyle, createStyleUtils } from '../common/createStyleUtils';
import { Box, Stack } from '@mui/material';
import curveProfileCountour from '../assets/trait/curve_profile_countour.png';
import maleHappy from '../assets/trait/male_happy.png';

export default function ChartProfile(props: Props) {
  const { containerSx = {}, imageSource } = props;

  return (
    <Stack sx={[styles.container, containerSx]}>
      <Box component={'img'} src={curveProfileCountour} />

      <Stack sx={[styles.imageContainer]}>
        <Box
          component={'img'}
          src={imageSource ?? maleHappy}
          sx={{
            width: '100%',
            height: '100%',
            borderRadius: '12px',
            objectFit: 'cover',
          }}
        />
      </Stack>
    </Stack>
  );
}

interface Props {
  containerSx?: AppStyle;
  imageSource?: string;
}

const styles = createStyleUtils({
  container: {
    width: 32,
    height: 37,
    position: 'relative',
  },

  imageContainer: {
    position: 'absolute',
    top: 5,
    left: 0,
    right: 0,
    bottom: 0,

    justifyContent: 'center',
    alignItems: 'center',
  },

  profileImage: {
    width: '24px',
    height: '24px',
  },
});
