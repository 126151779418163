import { Box, Stack, Typography } from '@mui/material';
import {
  geneticsScreenStyles,
  lqiViewStyles,
  traitViewDetailsStyles,
  traitViewStyles,
} from './styles';
import { useMemo } from 'react';
import { getSuffix } from './common';
import { ASSETS_URL } from '../environments/environments';
import DistributionChart from './DistributionChart';
import SNPSView from './SNPSView';
import {
  useGetTraitsDetailsQuery,
  useGetTraitsQuery,
  useGetUserInfoQuery,
} from '../api/api';
import { useParams } from 'react-router-dom';
import LargeLabel from './labels/LargeLabel';

export default function TraitDetails() {
  const {
    sectionCode = '',
    traitId = '',
    hash = '',
  } = useParams<{
    hash: string;
    traitId: string;
    sectionCode: string;
  }>();

  const { data: traitInfo } = useGetTraitsDetailsQuery(
    { hash, reportId: traitId },
    {
      skip: traitId === '',
    }
  );
  const { data: traits } = useGetTraitsQuery(
    { hash, groupId: sectionCode },
    {
      skip: sectionCode === '',
    }
  );
  const { data: user } = useGetUserInfoQuery(hash, { skip: hash === '' });

  const trait = useMemo(() => {
    return traits?.find((item) => item.id === traitId);
  }, [traitId, traits]);

  const {
    trait: { score, imgUrl, name } = { score: -1, imgUrl: '', name: '' },
    traitInfo: {
      score_label,
      description_plain,
      level_description_plain,
      level_action_plain,
      n_snps_pos,
      n_snps_neg,
      snps_pos,
      snps_neg,
    } = {
      score_label: '',
      description_plain: '',
      level_description_plain: '',
      level_action_plain: '',
      n_snps_pos: 0,
      n_snps_neg: 0,
      snps_pos: [],
      snps_neg: [],
    },
  } = { trait, traitInfo };

  const suffix = useMemo(() => {
    return getSuffix(score);
  }, [score]);

  const content = useMemo(() => {
    return (
      <>
        <Typography sx={lqiViewStyles.betterThan}>{score_label}</Typography>

        <DistributionChart
          distribution={score}
          showAverage={true}
          imageSource={
            user
              ? `https://d3f9ze44v0fr12.cloudfront.net/uploads/user/profile_image/${user.id}/${user.profile_image}`
              : undefined
          }
        />

        <Typography sx={[lqiViewStyles.title, { marginTop: '24px' }]}>
          What is it?
        </Typography>
        <Typography sx={lqiViewStyles.content}>{description_plain}</Typography>

        <Typography sx={[lqiViewStyles.title, { marginTop: '24px' }]}>
          Your results
        </Typography>
        <Typography sx={lqiViewStyles.content}>
          {level_description_plain}
        </Typography>

        <Typography sx={[lqiViewStyles.title, { marginTop: '24px' }]}>
          What it means for you
        </Typography>
        <Typography sx={lqiViewStyles.content}>{level_action_plain}</Typography>

        <Typography sx={lqiViewStyles.title}>
          Top positive contributors
        </Typography>

        <Typography sx={lqiViewStyles.content}>
          {`${n_snps_pos} genetic variants associated with positive impact on ${name} in total.`}
        </Typography>

        <Stack
          direction={'row'}
          sx={{ flexWrap: 'wrap', alignSelf: 'stretch' }}
        >
          {snps_pos.map((snp) => (
            <SNPSView key={snp.gene} snps={snp} />
          ))}
        </Stack>

        <Typography sx={lqiViewStyles.title}>
          Top negative contributors
        </Typography>

        <Typography sx={lqiViewStyles.content}>
          {`${n_snps_neg} genetic variants associated with positive impact on ${name} in total.`}
        </Typography>

        <Stack
          direction={'row'}
          sx={{ flexWrap: 'wrap', alignSelf: 'stretch' }}
        >
          {snps_neg.map((snp) => (
            <SNPSView key={snp.gene} snps={snp} />
          ))}
        </Stack>
      </>
    );
  }, [
    description_plain,
    level_action_plain,
    level_description_plain,
    n_snps_neg,
    n_snps_pos,
    name,
    score,
    score_label,
    snps_neg,
    snps_pos,
    user,
  ]);

  return (
    <Stack sx={geneticsScreenStyles.pricinplesContainer} direction={'column'}>
      <Stack sx={traitViewStyles.scorePercentileContainer} direction={'column'}>
        <Typography sx={traitViewDetailsStyles.score}>
          {Math.round(score * 100)}
        </Typography>
        <Typography sx={traitViewStyles.suffixTraitDetails}>
          {suffix}
        </Typography>
      </Stack>

      <Typography sx={traitViewStyles.percentileTraitDetails}>
        percentile
      </Typography>

      <Stack
        sx={traitViewDetailsStyles.nameContainer}
        justifyContent={'center'}
        alignItems={'center'}
      >
        <Box
          component={'img'}
          sx={traitViewDetailsStyles.image}
          src={
            imgUrl !== ''
              ? `${ASSETS_URL}/uploads/genetics_new/mobile/traits_new/${imgUrl}`
              : ''
          }
        />

        <Typography sx={traitViewDetailsStyles.name}>{name}</Typography>
      </Stack>

      <LargeLabel score={score} />

      {content}
    </Stack>
  );
}
