import { Box, Typography } from '@mui/material';
import { useLabelsUI } from './common';
import { createStyleUtils } from '../../common/createStyleUtils';

export default function SmallLabel(props: Props) {
  const { score } = props;

  const { circleColor, text } = useLabelsUI(score, true);

  return (
    <Box sx={styles.labelContainer}>
      <Box sx={[styles.circle, { backgroundColor: circleColor }]} />
      <Typography sx={styles.title}>{text}</Typography>
    </Box>
  );
}

const styles = createStyleUtils({
  labelContainer: {
    padding: '2px 8px',
    borderRadius: '36px',
    backgroundColor: '#F5F6F6',
    flexDirection: 'row',
    marginBottom: '12px',
    alignSelf: 'flex-start',
    display: 'flex',
  },

  circle: {
    marginRight: '4px',
    width: '10px',
    height: '10px',
    borderRadius: '5px',
  },

  title: {
    fontWeight: '500',
    fontSize: '10px',
    lineHeight: '12px',
    color: '#292D3299',
  },
});

interface Props {
  score: number;
}
