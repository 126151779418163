import { createTheme } from '@mui/material';

export const appTheme = createTheme({
  typography: {
    fontFamily: 'GeneralSans',
    allVariants: {
      color: '#292D32',
      fontWeight: '500',
    },
  },
});
