import { useMemo } from 'react';
import ChartProfile from './ChartProfile';
import { Box, Stack, Typography } from '@mui/material';
import lqiCurve from '../assets/trait/lqi_curve.png';
import { lqiCurveStyles } from './styles';

export default function DistributionChart(props: Props) {
  const { distribution, showAverage, imageSource } = props;

  const averageLine = useMemo(() => {
    return (
      <Stack
        direction={'column'}
        sx={[lqiCurveStyles.absoluteContainer, lqiCurveStyles.averageContainer]}
        justifyContent={'center'}
        alignContent={'center'}
      >
        <Box sx={lqiCurveStyles.averageLine} />
        <Typography sx={lqiCurveStyles.averageText}>avg.</Typography>
      </Stack>
    );
  }, []);

  return (
    <Stack sx={{ alignSelf: 'center' }}>
      <Stack sx={lqiCurveStyles.container} direction={'column'}>
        <img src={lqiCurve} style={{ width: '100%' }} />

        <Stack
          sx={[
            lqiCurveStyles.separator,
            {
              marginLeft: `calc(38px + ${distribution} * (100% - 38px - 33px))`,
              transform: 'translateX(-50%)',
            },
          ]}
        />
        {showAverage && averageLine}
      </Stack>

      <ChartProfile
        imageSource={imageSource}
        containerSx={{
          marginLeft: `calc(38px + ${distribution} * (100% - 38px - 33px))`,
          transform: 'translateX(-50%)',
        }}
      />
    </Stack>
  );
}

interface Props {
  distribution: number;
  imageSource?: string;
  showAverage?: boolean;
}
